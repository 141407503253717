.tree {
  height: 100%;

  .section-text {
    fill: #cccccc;
  }

  .scroll-parent {
    overflow-y: hidden;
    overflow-x: hidden;
    pointer-events: all;
  }

  .scroll-parent:hover {
    overflow-x: auto;
    overflow-y: auto;
  }

  .stats-parent {
    padding-right: 4px;
  }

  .scroll-parent::-webkit-scrollbar {
    width: 5px;
    border-radius: 8px;
  }

  .scroll-parent::-webkit-scrollbar-thumb {
    background-color: #ccc;
    outline: none;
    box-shadow: none;
    width: 5px;
    border-radius: 8px;
  }

  .scroll-parent::-webkit-scrollbar-track {
    width: 5px;
    border-radius: 8px;
    background-color: #f2f2f2;
  }

  .scroll-parent::-moz-range-thumb {
    background-color: #ccc;
    outline: none;
    box-shadow: none;
    width: 5px;
  }

  .table-grid:focus::-moz-range-thumb {
    box-shadow: none;
  }

  .scroll-parent::-moz-range-thumb:active {
    background-color: #ccc;
  }

  .scroll-parent::-moz-range-track {
    width: 5px;
    background-color: #f2f2f2;
  }

  .statistic-group {
    margin-bottom: 5px;
  }

  .stat-title {
    color: #666666;
    font-size: 10px;
    line-height: 10px;
    user-select: none;
  }

  .stat-rect-group {
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .stat-rect {
    height: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .stat-text {
    font-size: 8px;
    line-height: 8.1px;
  }
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.w-100 {
  width: 100% !important;
}

.btn-split {
  padding: 0.25rem 0.5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  font-weight: 400;
  border: 1px solid transparent;
  background-color: #fff;
  color: #28a745;
  border-color: #28a745;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-outline-success:hover {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.form-check {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
