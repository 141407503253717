
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
img.loading {
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.small-control {
  height: calc(1.5em + 2px) !important;
  padding: 0px !important;
  font-size: .875rem;
  line-height: 1 !important;
  border-radius: .2rem;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}